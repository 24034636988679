import React from "react";

import Products from "../products";
import ProductCard from "../../components/ProductCards/ProductCards";
import cervezas from "../../data/cerveza";
import SEO from "../../components/SEO/Seo";

const Beers = () => {
  return (
    <>
      <SEO
        title="Blue Sky SA - Botellas de Vidrio. Cervezas. Cerveceras"
        keywords="Blue Sky SA , Botellas , Botellas de vidrio , Tapas rosca , Corchos , Tapones , Argentina , Mendoza , Tapas Pilfer , Screw Caps , Aluminio.  -Blue Sky SA - Botellas de vidrio .Blue Sky SA - Botellas de vidrio . Cervezas. Cerveceras. Growler. Alta, baja, con picada, sin picada, 330, 350 y 500 ml. Color verde y ambar. Boca corona y MCA. Mendoza - Argentina."
        description=" Encuentra botellas Cervezas. Cerveceras  en Blue Sky SA.  Blue Sky SA - Botellas de vidrio . Cervezas. Cerveceras. Growler. Alta, baja, con picada, sin picada, 330, 350 y 500 ml. Color verde y ambar. Boca corona y MCA. Mendoza - Argentina."
      />
      <div className="cerveza-background">
        <Products subtitle="Cervezas">
          <ProductCard data={cervezas} />
        </Products>
      </div>
    </>
  );
};

export default Beers;
