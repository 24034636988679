import {
  Cerveza1,
  Cerveza2,
  Cerveza3,
  Cerveza4,
  Cerveza5,
  Cerveza6,
  Cerveza7,
  Cerveza8,
  Cerveza9,
  Cerveza10,
  Cerveza11,
  Cerveza12,
  Cerveza13,
  Cerveza14,
  Cerveza1_3,
  Cerveza2_3,
  Cerveza3_3,
  Cerveza4_3,
  Cerveza5_3,
  Cerveza6_3,
  Cerveza7_3,
  Cerveza8_3,
  Cerveza9_3,
  Cerveza10_3,
  Cerveza11_3,
  Cerveza12_3,
  Cerveza13_3,
  Cerveza14_3,
  SeparadorXL,
  SeparadorVasos
} from "../images/productos/cervezas";

import {
  PDF1,
  PDF2,
  PDF3,
  PDF4,
  PDF5,
  PDF6,
  PDF7,
  PDF8,
  PDF9,
  PDF10,
  PDF11,
  PDF12,
  PDF13,
  PDF14,
} from "../../static/13-cervezas/index";

const cervezas = [
  { size: 375, src: Cerveza1, srcModal: Cerveza1_3, pdfDownload: PDF1 },
  { size: 375, src: Cerveza3, srcModal: Cerveza3_3, pdfDownload: PDF3 },
  { size: 375, src: Cerveza5, srcModal: Cerveza5_3, pdfDownload: PDF5 },
  { size: 375, src: Cerveza7, srcModal: Cerveza7_3, pdfDownload: PDF7 },
  { size: 375, src: Cerveza9, srcModal: Cerveza9_3, pdfDownload: PDF9 },
  { src: SeparadorXL, divider: true },

  { size: 375, src: Cerveza10, srcModal: Cerveza10_3, pdfDownload: PDF10 },
  // { src: SeparadorVasos, divider: true },

];

export default cervezas;
